<ul class="nav nav-tabs ">
  <li class="nav-item"><a class="nav-link" routerLink="change-detection"  routerLinkActive="active actiefMenu">Change detection</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="directive"  routerLinkActive="active actiefMenu">Directive</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="dynamic"  routerLinkActive="active actiefMenu">Dynamic load</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="general"  routerLinkActive="active actiefMenu">General</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="identity"  routerLinkActive="active actiefMenu">Identity</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="libraries"  routerLinkActive="active actiefMenu">Libraries</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="module"  routerLinkActive="active actiefMenu">Module</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="parent-child"  routerLinkActive="active actiefMenu">Parent child</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="promise"  routerLinkActive="active actiefMenu">Promise</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="signal"  routerLinkActive="active actiefMenu">Signal</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="syntax"  routerLinkActive="active actiefMenu">Syntax</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="ui-testen"  routerLinkActive="active actiefMenu">UI testen</a><li>
  <li class="nav-item"><a class="nav-link" routerLink="unit-tests"  routerLinkActive="active actiefMenu">Unit tests</a><li>
</ul>
<br/>
<router-outlet></router-outlet>
