<h3>Algemeen</h3><br>
een observable doet of een error of een complete, na error of complete komen geen nieuwe values meer.<br>

<h3>ConcatMap / mergeMap / exhaustMap</h3>
Voorbeeld van een autoSave<br>
Concat map pakt een waarde uit de valueChanges, maakt een observable voor this.savecourse() en doet daar ook een subscribe op zodat deze wordt uitgevoerd.<br>
In het eerste voorbeeld stond een subscribe binnen een subscribe.<br>
In de aangepaste code is de oplossing met concatmap gemaakt.<br>
Bij <code>concatMap</code> wordt altijd gewacht tot de 2e observable klaar is, voordat een nieuwe waarde uit de eerste observable wordt gepakt. Als je dat wachten niet wilt, gebruik je <code>mergeMap</code>. Er wordt dan een nieuwe Save aangeroepen terwijl de vorige nog niet klaar is.<br>
<code>ConcatMap</code> doet ze sequentieel / in volgorde, <code>mergeMap</code> doet ze parallel.<br>
<code>ExhaustMap</code> voert de save uit, als er een change is. Maar, als er een change is VOOR de save gereed is, dan wordt deze change genegeerd.<br>
<code>MergeMap</code> voert de save uit, als er een change is. Maar, als er een change is VOOR de save gereed is, dan wordt de huidige save afgebroken (unsubscribe) en wordt er een nieuwe save gestart.<br>
<img src = "/assets/concatmap.png" class="medium"><br>
<img src = "/assets/exhaustmap.png" class="medium"><br>

<h3>Custom RxJs operator</h3>
De <code>debug</code> custom operator. Zie deze component, waar hij wordt gebruikt.<br>

<h3>Custom observable obv een promise</h3>
Een promise die een HTTP call doet, omgebouwd naar een observable. Observable doet 3 keer een <code>next()</code> (uitgecomment)<br>
<button class="btn btn-info" (click)="startCustomObservable()">Start custom observable</button><br>

<h3>Mergemap</h3>
<pre>
  <code [innerHTML]="mergeMap"></code>
</pre>



<h3>ForkJoin</h3>
Voert 2 of meer observables parallel uit, en als ze allemaal gereed zijn krijg je de data tegelijk terug.<br>
Bijvoorbeeld als je 3 lijstjes moet ophalen van de backend en je hebt ze alle 3 nodig voor je verder kunt.<br>
Als een observable meerdere waardes teruggeeft dan krijg je de laatste.
Zie code voorbeeld in component.<br>
<button class="btn btn-info" (click)="startForkJoin()">Start fork join</button><br>


<h3>FromEvent</h3>
Een steam op een event op de DOM, in dit geval document click. Geeft stream waarop meerdere abonnementen mogelijk zijn.<br>
In dit voorbeeld een <code>foreach</code> en 2 <code>scubscribe</code> schrijven in de console log.<br>
<button class="btn btn-info" (click)="startDocumentClick()">Start document click</button><br>
Als je vaker klikt, worden er meerdere streams gestart.<br>
Na 10 seconden wordt de subscription automatisch beeindigd, behalve de foreach, want dat is geen subscription.<br>

<h3>Interval</h3>
RxJs versie van <code>window.setInterval</code>. Geeft stream waarop meerdere abonnementen mogelijk zijn.<br>
In dit voorbeeld een <code>foreach</code> en 2 <code>scubscribe</code> schrijven in de console log.<br>
<button class="btn btn-info" (click)="startInterval()">Start interval</button><br>
Als je vaker klikt, worden er meerdere streams gestart.<br>
Na 10 seconden wordt de subscription automatisch beeindigd, behalve de foreach, want dat is geen subscription.<br>


<h3>Map array naar een ander object</h3>
Mapt een array van uitgebreide <code>User</code> objecten naar een <code>UserViewmodel</code> object<br>
<button class="btn btn-info" (click)="startMap()">Start map</button><br>

@if ((users$ | async)?.length) {
<table class="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Company</th>
    </tr>
  </thead>
  <tbody>
    @for (user of (users$ | async); track user; let i = $index) {
<tr>
      <th scope="row">{{user.id}}</th>
      <td>{{user.name}}</td>
      <td>{{user.email}}</td>
      <td>{{user.company}}</td>
    </tr>
}

  </tbody>
</table>
}


<h3>Merge</h3>
Merge meerdere observables in 1.<br>
If order not throughput is a primary concern, try <code>concat</code> instead.<br>
<button class="btn btn-info" (click)="startMerge()">Start merge</button><br>


<h3>Sequential concatenation van 3 observables en filter</h3>
Als de eerste observable complete, dan volgende waardes uit de 2e en 3e observable<br>
eerste emits 1tm10. de 2e 11tm20 en de 3e 21tm30.<br>
Met een filter worden alleen de oneven getoond.<br>

<button class="btn btn-info" (click)="startConcat()">Start concat</button><br>

<h3>Type-ahead</h3>
Observable op de key-up van een input box, met een <code>debounceTime</code>. Die emit pas een waarde als de input x milliseconden stabiel is.
<input  class="form-control" #searchDebounce placeholder="search debouce" type="text">

Observable op de key-up van een input box, met een <code>throttle</code>. Die emit een waarde als output van een andere observable er is. Bijvoorbeeld maximaal 1 keer per seconde een output emitten. (is geen goed voorbeeld voor een type-ahead want je kunt hierbij wel de laatste waardes missen.)
<input  class="form-control" #searchThrottle placeholder="search throttle" type="text">
<br>

<h3>withLatestFrom</h3>
Combines the source Observable with other Observables to create an Observable whose values are calculated from the latest values of each, only when the source emits.<br>
<img class="small" src="/assets/withlatestfrom1.png" /><br>
<img class="small" src="/assets/withlatestfrom2.png" />
