import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-routing-index',
    templateUrl: './routing-index.component.html',
    styleUrls: ['./routing-index.component.sass'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, RouterOutlet]
})
export class RoutingIndexComponent  {
}
