import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { from } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-fiets',
    templateUrl: './fiets.component.html',
    styleUrls: ['./fiets.component.sass'],
    standalone: true
})
export class FietsComponent  {
  dataVanuitRoute: any;
  urlAfterRedirects: string = 'unknown';
  event: any;

  constructor(private readonly router: Router) {
    const state = this.router.getCurrentNavigation()?.extras?.state?.waarde;
    console.debug('state in FietsComponent in constructor', state);
    if (state && state as string) {
      this.dataVanuitRoute = state;
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
      this.writeUrlAfterRedirect((event as NavigationEnd).urlAfterRedirects);
    });

    this.router.events.subscribe((event: any) => { this.writeEvent(event) });


    this.stukjeRxJs();
  }

  stukjeRxJs() {
    const arrayDataObservable$ = from([1, 2, 3, 4, 5]);

    const dataPipeline = arrayDataObservable$.pipe(
      tap(val => val),//console.debug('Value passing through the stream: ' + val)),
      filter(val => val > 2),
      map(val => val * 2)
    )

    const subscribeToDataPipeline = (subscriberName: string) => {
      return dataPipeline.subscribe(val => {
        console.debug(subscriberName + ' received: ' + val);
      })
    }
    const subscription1 = subscribeToDataPipeline('Subscriber1');

    subscription1.unsubscribe();
  }

  writeEvent(event: any) {
    this.event = event;
    //console.debug('Event uit de router events in het fietscomponent: ', event)
  }

  writeUrlAfterRedirect(urlAfterRedirects: string) {
    this.urlAfterRedirects = urlAfterRedirects;
    //console.debug('urlAfterRedirects uit de router events in het fietscomponent: ', urlAfterRedirects)
  }
}
