import { InjectionToken } from "@angular/core"

export interface AppConfig {
  apiUrl:string;
  cacheSize:number;
}

export const APP_CONFIG : AppConfig = {
  apiUrl:'http://localhost:9200',
  cacheSize: 10
}

export const APPCONFIGTOKEN = new InjectionToken<AppConfig>('APPCONFIGTOKEN')


export interface StatsConfig {
  pageName:string;
  hitCount:number;
}

export const STATS_CONFIG : StatsConfig = {
  pageName:'/general/faq',
  hitCount: 15
}
export const STATS_CONFIG2 : StatsConfig = {
  pageName:'/routes/new',
  hitCount: 15
}
export const STATSCONFIGTOKEN = new InjectionToken<StatsConfig>('STATSCONFIGTOKEN', {
  providedIn: 'root',
  factory: () => STATS_CONFIG
})
