import { Component } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.sass'],
    standalone: true
})
export class HomeComponent  {
  public  title = "Gebruikersbeheer 🍭";
}
