import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.sass'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, RouterOutlet]
})
export class NewsComponent {

}
