import { enableProdMode, ErrorHandler, isDevMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ProductData } from './app/redux/products/product-data';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MyErrorHandler } from './app/general/MyErrorHandler';
import { HttpClient, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';
import { APP_ROUTES } from './app/app-routes';
import { userReducer } from './app/redux/user/state/user.reducer';
import { productReducer } from './app/redux/products/state/product.reducer';
import { ProductsEffects } from './app/redux/products/state/products.effects';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom(
          HttpClient,
          BrowserModule,
          ReactiveFormsModule,
          HttpClientInMemoryWebApiModule.forRoot(ProductData),
          StoreModule.forRoot({}),
          StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !isDevMode(),
            autoPause: true,
            trace: false,
            traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
          }),
          EffectsModule.forRoot([]),
          StoreModule.forFeature('user', userReducer),
          StoreModule.forFeature('products', productReducer),
          EffectsModule.forFeature([ProductsEffects])
          ),
        // {provide: HTTP_INTERCEPTORS, useClass: AddHeaderInterceptor, multi:true},
        // {provide: HTTP_INTERCEPTORS, useClass: LogRequestInterceptor, multi:true},
        // {provide: HTTP_INTERCEPTORS, useClass: EditResponseInterceptor, multi:true},
        // {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi:true},
        { provide: ErrorHandler, useClass: MyErrorHandler },

        provideAnimations(),
        provideRouter(APP_ROUTES),
    ]
})
  .catch(err => console.error(err));
