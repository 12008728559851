import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Temperatuur } from "../Temperatuur";
import { TemperatuurService } from "../temperatuur.service";

@Injectable({
  providedIn: 'root'
})
export class CurrentTempAvailableResolver  {
  constructor(private temperatuurService: TemperatuurService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Temperatuur> {
    console.debug('resolving CurrentTempAvailableResolver');

    let temp = this.temperatuurService.getCurrent();
    if (temp && temp.waarde >= 0) {
        return of(temp);
    } else {
      this.router.navigate(['/temperatuur/te-koud']);
      console.debug(`geen hoog genoeg temperatuur (${temp.waarde}) gevonden door de CurrentTempAvailableResolver`)
      return of(null as unknown as Temperatuur);
    }
  }
}
