import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Temperatuur } from '../Temperatuur';

@Component({
    selector: 'app-current-temp',
    templateUrl: './current-temp.component.html',
    styleUrls: ['./current-temp.component.sass'],
    standalone: true
})
export class CurrentTempComponent implements OnInit {
  temp: Temperatuur | null = null;
  temp2: Temperatuur | null = null;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    console.debug('this.route.snapshot.data in CurrentTempComponent', this.route.snapshot.data);
    this.temp = this.route.snapshot.data['dataVoorDezeRoute'];
    this.route.data.subscribe(data => this.temp2  = data['dataVoorDezeRoute'])
  }

}
