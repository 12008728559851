import { Injectable } from '@angular/core';
import { Temperatuur } from './Temperatuur';

@Injectable({
  providedIn: 'root'
})
export class TemperatuurService {
  getCurrent(): Temperatuur {
    let temp: number  = Math.floor(Math.random() * 40) - 10;
    console.debug(`Gegenereerde temperatuur in de TemperatuurService is ${temp}`);
    return new Temperatuur(temp);
  }
}
