import { createAction, createReducer, on } from "@ngrx/store";
import { UserState } from "./user.state";
import * as UserActions from './user.actions';


const initialState: UserState = {
  maskUserName: false
}

export const userReducer = createReducer<UserState>(
  initialState,
  on(UserActions.maskUserNameAction, (state) : UserState => {
    console.log('[userReducer] original state: ', JSON.stringify(state));
    return {
      ...state,
      maskUserName: !state.maskUserName
    };
  })
);


