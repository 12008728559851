export class Geo {
  lat: string = '';
  lng: string = '';
}

export class Address {
  street: string = '';
  suite: string = '';
  city: string = '';
  zipcode: string = '';
  geo: Geo = new Geo();
}

export class Company {
  name: string = '';
  catchPhrase: string = '';
  bs: string = '';
  constructor(
    name: string =  '',
    catchPhrase: string =  '',
    bs: string =  ''){
      this.name = name;
      this.catchPhrase = catchPhrase;
      this.bs = bs;
    }
}

export class User {
  id: number = 0;
  name: string = '';
  username: string = '';
  email: string = '';
  address: Address = new Address();
  phone: string = '';
  website: string = '';
  company: Company = new Company();
}

export class UserViewmodel {
  constructor(
  public id: number = 0,
  public name: string = '',
  public email: string = '',
  public company: string = ''){}
}

