import { createAction, props } from "@ngrx/store";
import { Product } from "../product";

export const toggleProductCodeAction = createAction('[Product] Toggle Product Code');


export const setCurrentProductAction =
  createAction('[Product] Set Current Product', props<{product: Product}>());

export const clearCurrentProductAction = createAction('[Product] Clear Current Product');

export const initializeCurrentProductAction = createAction('[Product] Initialize Current Product');

export const loadProductsAction = createAction('[Product] Load products');

export const loadProductsSuccessAction = createAction('[Product] Load products success', props<{products: Product[]}>());

export const loadProductsFailedAction = createAction('[Product] Load products failed', props<{errorMessage: string}>());

export const updateProductAction = createAction('[Product] Update product', props<{product: Product}>());

export const updateProductSuccesAction = createAction('[Product] Update product success', props<{product: Product}>());

export const updateProductFailedAction = createAction('[Product] Update product failed', props<{errorMessage: string}>());

export const createProductAction = createAction('[Product] create product', props<{product: Product}>());

export const createProductSuccesAction = createAction('[Product] create product success', props<{product: Product}>());

export const createProductFailedAction = createAction('[Product] create product failed', props<{errorMessage: string}>());

export const deleteProductAction = createAction('[Product] delete product', props<{product: Product}>());

export const deleteProductSuccesAction = createAction('[Product] delete product success', props<{productId: number}>());

export const deleteProductFailedAction = createAction('[Product] delete product failed', props<{errorMessage: string}>());

export const cancelEditAction = createAction('[Product] cancel edit ');
