import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Event, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { RoutingData } from './models/routing-data';
import { filter, tap } from 'rxjs/operators';
import { AppConfig, APPCONFIGTOKEN, APP_CONFIG, StatsConfig, STATSCONFIGTOKEN } from './general/dependency-injection/config';
import { StoreService } from './store/store.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    providers: [
        { provide: APPCONFIGTOKEN, useFactory: () => APP_CONFIG }
    ],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, RouterOutlet]
})
export class AppComponent {
  title = 'algemeen';
  constructor(
    private readonly titleService: Title,
    @Inject(APPCONFIGTOKEN)private readonly appConfig: AppConfig,
    @Inject(STATSCONFIGTOKEN)private readonly statsConfig: StatsConfig,
    private readonly router: Router,
    private readonly storeService: StoreService) {
      console.debug('appConfig', appConfig);
      console.debug('statsConfig', statsConfig);
      router
        .events
        .pipe(
          tap(event => {
           console.debug(event)
          })
         )
        .pipe(
          filter((e: Event) => e instanceof ActivationStart)
        )
        .subscribe((event: Event) => {
          const routeData = (event as ActivationStart).snapshot.data as RoutingData;
          if (routeData) {
            titleService.setTitle(routeData.pageTitle);
          }
      });
      storeService.init();
  }
}
