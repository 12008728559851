import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { concat, forkJoin, fromEvent, interval, merge, noop, Observable, Observer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mapTo, shareReplay, tap, throttle } from 'rxjs/operators';
import { debug } from './debug';
import { Company, User, UserViewmodel } from './models';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-rxjs',
    templateUrl: './rxjs.component.html',
    styleUrls: ['./rxjs.component.sass'],
    standalone: true,
    imports: [AsyncPipe]
})
export class RxjsComponent implements  AfterViewInit{

  @ViewChild('searchDebounce') searchDebounce : ElementRef | null = null;
  @ViewChild('searchThrottle') searchThrottle : ElementRef | null = null;

  users$: Observable<UserViewmodel[]> = of([]);

  ngAfterViewInit() : void{
    const keypressedDebounce$ = fromEvent(this.searchDebounce?.nativeElement, 'keyup')
    .pipe(map ((event: any) => event.target.value));

    keypressedDebounce$
    .pipe(
      tap(v => console.debug(`directe keyup in debounce: ${v}`)),
      debug(1, 'tekst vanuit een custom RxMvD operator'),
      debounceTime(500),
      distinctUntilChanged())
    .subscribe( val => console.debug(`debounceTime keyup: ${val}`));

    // ++++++++++++++++++++++++++++++++++++++ //
    const keypressedThrottle$ = fromEvent(this.searchThrottle?.nativeElement, 'keyup')
    .pipe(map ((event: any) => event.target.value));

    keypressedThrottle$.subscribe( val => console.debug(`directe keyup in throttle: ${val}`));
    keypressedThrottle$
    .pipe(
      throttle(() => interval(800)), // of throttleTime(800) is een alias voor deze code
      distinctUntilChanged())
    .subscribe( val => console.debug(`Throttle keyup: ${val}`));
  }

  startInterval() {
    const interval$ = interval(1000);
    const subscription1 = interval$.forEach((val) => { console.debug(`Interval emitted from forEach ${val}`) });
    const subscription2 = interval$.subscribe((val) => { console.debug(`Interval emitted from 1e subscribe ${val}`) });
    const subscription3 = interval$.subscribe((val) => { console.debug(`Interval emitted from 2e subscribe ${val}`) });
    setTimeout(() => {
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    }, 10000);
  }

  startDocumentClick() {
    const click$ = fromEvent(document, 'click');
    const subscription1 = click$.forEach((val) => { console.debug(`FromEvent emitted from forEach ${val}`) });
    const subscription2 = click$.subscribe((val) => { console.debug(`FromEvent emitted from 1e subscribe ${val}`) });
    const subscription3 = click$.subscribe((val) => { console.debug(`FromEvent emitted from 2e subscribe ${val}`) });
    setTimeout(() => {
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    }, 10000);
  }


  startCustomObservable() {
    const http$ = this.getCustomObservable().pipe(
      tap(z => console.debug(`tap op de http$ observable, deze krijgt ${z.length} users binnen.`)),
      shareReplay() //zorgt er voor dat er niet meerdere keren een HTTP call wordt gedaan
    );

    const subscription = http$.subscribe(
      (users: User[]) => {
        console.debug(`losse subscribe op de http$ die ${users.length} users ontvangt.`);
        console.debug('de users-data is:', users);
      },
      noop,
      () => console.debug('losse subscribe op de http$ completed')
    );
  }

  forkJoinCompany1 = '';
  forkJoinCompany2 = '';
  forkJoinNamen1 = '';
  forkJoinNamen2 = '';
  forkJoinError = '';
  startForkJoin(){
    this.forkJoinCompany1 = '';
    this.forkJoinCompany2 = '';
    this.forkJoinNamen1 = '';
    this.forkJoinNamen2 = '';
    this.forkJoinError = '';
    const company:Company = new Company('Affinno', 'The Best', 'N/A/');
    const company$ = of(company);
    const namen$=of(['jan', 'piet', 'klaas']);
    const observables =[company$, namen$];
    forkJoin(observables).pipe(
      tap(([c, n]) => {
        this.forkJoinCompany1 = (c as Company).name;
        this.forkJoinNamen1 = (n as []).join();
        console.debug('company', c);
        console.debug('names', n);
      } )

    ) .subscribe({
      next: (forkResult) => {
        this.forkJoinCompany2 = (forkResult[0] as Company).name;
        this.forkJoinNamen2 = (forkResult[1] as []).join();
        console.debug('forkResult', forkResult);
      },
      error: (err) => {
        this.forkJoinError = '';
        console.debug(err)
      }
    });
  }


  startMap() {
    const http$ = this.getCustomObservable().pipe(
      map (z => convertUserToUserViewmodel(z)),
      shareReplay()
    );

    this.users$ = http$;
  }

  startMerge(){
    //emit every 2.5 seconds
    const first = interval(2500);
    //emit every 2 seconds
    const second = interval(2000);
    //emit every 1.5 seconds
    const third = interval(1500);
    //emit every 1 second
    const fourth = interval(1000);

    //emit outputs from one observable
    const example = merge(
      first.pipe(map(() => 'FIRST!')),
      second.pipe(map(() => 'SECOND!')),
      third.pipe(map(() => 'THIRD')),
      fourth.pipe(map(() => 'FOURTH'))
    );
    //output: "FOURTH", "THIRD", "SECOND!", "FOURTH", "FIRST!", "THIRD", "FOURTH"
    const subscribe = example.subscribe((val: string) => console.debug(val));
  }

  startConcat(){
    const source1$ = of(1,2,3,4,5,6,7,8,9,10);
    const source2$ = of(11,12,13,14,15,16,17,18,19,20);
    const source3$ = of(21,22,23,24,25,26,27,28,29,30);
    const combined$ = concat(source1$, source2$, source3$)
      .pipe(filter (val => val % 2 != 0));
    combined$.subscribe(console.debug);
    console.debug('ready. Nu een combined met een 3e die blijft emitten')
    const source1b$ = of(1,2,3,4,5,6,7,8,9,10);
    const source2b$ = of(11,12,13,14,15,16,17,18,19,20);
    const source3b$ = interval(500);
    const combinedb$ = concat(source1b$, source2b$, source3b$);
    combinedb$.subscribe(console.debug);
  }
  private getCustomObservable(): Observable<User[]> {
    const apiUrl = 'https://jsonplaceholder.typicode.com/users';

    const http$ = new Observable((observer: Observer<User[]>) => {
      fetch(apiUrl)
        .then(response => {
          console.debug('response', response);
          return response.json();
        })
        .then(body => {
          console.debug('body', body);
          observer.next(body);
          // observer.next(body);
          // observer.next(body);
          observer.complete();
        })
        .catch(err =>
          observer.error(err)
        );
      return () => console.debug('De observable is unsubscribed');
    }
    );
    return http$;
  }

  mergeMap  = `
  import { of, mergeMap, interval, map } from 'rxjs';

  const letters = of('a', 'b', 'c');
  const result = letters.pipe(
    //mergeMap(x => interval(1000).pipe(map(i => x + i)))
    mergeMap(x => x + '1')
  );

  result.subscribe(x => console.log(x));


`
}
function convertUserToUserViewmodel(users: User[]): UserViewmodel[] {
  return users.map(user => new UserViewmodel(user.id, user.name, user.email, user.company?.name));
}

