<p>{{temp?.waarde}} graden (sync gelezen)</p>
<p>{{temp2?.waarde}} graden (async gelezen)</p>
<br/>
<br/>
<br/>
Resolver toegevoegd voor huidige temperatuur in eigen module.<br/>
Als het boven nul is (random temp) dan wordt de temperatuur getoond, anders een te koud pagina.<br/>
<br/>
Resolver:<br/>
Haalt voor het navigeren al data op.<br/>
Je zet de resolver op de route (1), en dan wordt deze automatisch uitgevoerd als de route wordt geactiveerd.<br/>
Retourneert data (of niet) en indien je niet wilt dat de originele navigatie wordt gevolgd, navigeer je in de resolver naar een andere route.<br/>
De data kun je in het component waar de route naar navigeert ophalen (behalve als je in de resolver null retourneert) (2)<br/>
In de module moet je de resolver als provider aangeven (3)<br/>
Omdat het wat langer kan duren voor de data wordt opgehaald en je dan niets ziet gebeuren (omdat de routing cq het tonen van het nieuwe component pas na de resolve gebeurt), is de regel van JP: bij een resolve altijd een spinner.<br/>
<br/>
<pre>
(1)  path: 'temperatuur/huidige-temperatuur', component: CurrentTempComponent, resolve: &#123;
    dataVoorDezeRoute: CurrentTempAvailableResolver
    &#125;

  </pre>

    <pre>
(2)  this.temp = this.route.snapshot.data['dataVoorDezeRoute'];
</pre>
<pre>
(3) providers: [ CurrentTempAvailableResolver ],
</pre>
<img class="medium" src="/assets/route resolvers 07.png">
