<h1>{{title}}</h1>

<ul class="nav nav-tabs ">
  <li class="nav-item">
    <a class="nav-link" routerLink="temperatuur/huidige-temperatuur"  routerLinkActive="active actiefMenu">Huidige temperatuur</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="faq" routerLinkActive="active actiefMenu">FAQ</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="formulieren" routerLinkActive="active actiefMenu">Formulieren</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="http" routerLinkActive="active actiefMenu">HTTP</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="news" routerLinkActive="active actiefMenu">News</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="paging" routerLinkActive="active actiefMenu">Paging</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="redux" routerLinkActive="active actiefMenu">Redux</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="routing" routerLinkActive="active actiefMenu">Routing</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="rxjs" routerLinkActive="active actiefMenu">RxJs</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="store" routerLinkActive="active actiefMenu">Store</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="tooling" routerLinkActive="active actiefMenu">Tooling</a>
  </li>
</ul>
<br>
<!-- add the selector from the CustomerDashboardComponent -->
<router-outlet ></router-outlet>
<div class="outlet2">
  <router-outlet name="popup"></router-outlet>
</div>


