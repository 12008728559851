import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductService } from '../product.service';
import * as ProductActions from './products.actions';
import { catchError, concatMap, map, mergeMap, of, tap } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductsEffects {

  constructor(private actions$: Actions, private productService: ProductService) {}

  loadProduct$ = createEffect( () => {
    return this.actions$
    .pipe(map(p => {
      console.log("action in ProductsEffects.loadProduct is ", p.type)
      return p;
    }))
    .pipe(map(p => p))
    .pipe(
      ofType(ProductActions.loadProductsAction),
      mergeMap(
        (iets) => this.productService.getProducts()
        .pipe(tap(value => {
          console.log('data in ProductsEffects.loadProduct is ', value);
        }))
        .pipe(
          map(products => ProductActions.loadProductsSuccessAction({products})),
          catchError(error => of (ProductActions.loadProductsFailedAction({errorMessage: error.toString()})))
        )
      )
    )
  });

  updateProduct$ = createEffect( () => {
    return this.actions$
    .pipe(map(p => {
      console.log("action in ProductsEffects.updateProduct is ", p.type)
      return p;
    }))
    .pipe(map(p => p))
    .pipe(
      ofType(ProductActions.updateProductAction),
      concatMap(action =>
        this.productService.updateProduct(action.product)
        .pipe(tap(value => {
          console.log('data in ProductsEffects.updateProduct is ', value);
        }))
        .pipe(
          map(product => ProductActions.updateProductSuccesAction({product})),
          catchError(error => of (ProductActions.updateProductFailedAction({errorMessage: error.toString()})))
        )
      )
    )
  });

  createProduct$ = createEffect( () => {
    return this.actions$
    .pipe(map(p => {
      console.log("action in ProductsEffects.createProduct is ", p.type)
      return p;
    }))
    .pipe(map(p => p))
    .pipe(
      ofType(ProductActions.createProductAction),
      concatMap(action =>
        this.productService.createProduct(action.product)
        .pipe(tap(value => {
          console.log('data in ProductsEffects.createProduct is ', value);
        }))
        .pipe(
          map(product => ProductActions.createProductSuccesAction({product})),
          catchError(error => of (ProductActions.createProductFailedAction({errorMessage: error.toString()})))
        )
      )
    )
  });

  deleteProduct$ = createEffect( () => {
    return this.actions$
    .pipe(map(p => {
      console.log("action in ProductsEffects.deleteProduct is ", p.type)
      return p;
    }))
    .pipe(map(p => p))
    .pipe(
      ofType(ProductActions.deleteProductAction),
      concatMap(action =>
        this.productService.deleteProduct(action.product.id ?? 0)
        .pipe(tap(value => {
          console.log('data in ProductsEffects.deleteProduct is ', value);
        }))
        .pipe(
          map(product => ProductActions.deleteProductSuccesAction({productId : action.product.id || 0})),
          catchError(error => of (ProductActions.deleteProductFailedAction({errorMessage: error.toString()})))
        )
      )
    )
  });
}
