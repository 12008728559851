import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './models/models';

@Injectable({
  providedIn: 'root'
})
export class StoreService {


  // Component die de service gebruikt wordt steeds opnieuw aangemaakt dus er wordt steeds opnieuw ge-subscribed,
  // dus moeten we een <code>subject</code> hebben die late subscribers ook de (laatste versie van de) data geeft.

  private subject  = new BehaviorSubject<User[]>([]);

  users$ : Observable<User[]> = this.subject.asObservable();

  // init wordt aangeroepen vanuit de app.component
  init() {
    console.debug('StoreService init');
    const http$ = this.getCustomObservable();
    http$.subscribe(users => this.subject.next(users));
  }

  selectBizUsers(): Observable<User[]> {
    return this.users$.pipe(
      map (users => users.filter(user => user.email.endsWith('biz')))
    );
  }

  editData(){
    const users = this.subject.getValue();
    const newUsers = users.slice(0);

    newUsers[0] = {
      ...users[0],
      name: 'Harry'
    };

    this.subject.next(newUsers);
  }

  // duplicaat van de rxjs module
  private getCustomObservable(): Observable<User[]> {
    const apiUrl = 'https://jsonplaceholder.typicode.com/users';

    const http$ = new Observable((observer: Observer<User[]>) => {
      fetch(apiUrl)
        .then(response => {
          return response.json();
        })
        .then(body => {
          observer.next(body);
          observer.complete();
        })
        .catch(err =>
          observer.error(err)
        );
      return () => console.debug('De observable is unsubscribed');
    }
    );
    return http$;
  }
}
