import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FaqIndexComponent } from './faq/faq-index/faq-index.component';
import { FietsComponent } from './general/fiets/fiets.component';
import { HomeComponent } from './general/home/home.component';
import { NotFoundComponent } from './general/not-found/not-found.component';
import { RoutingData } from './models/routing-data';
import { RoutingIndexComponent } from './routing/routing-index/routing-index.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { CurrentTempComponent } from './temperatuur/current-temp/current-temp.component';
import { CurrentTempAvailableResolver } from './temperatuur/resolvers/current-temp-available-resolver';
import { TeKoudComponent } from './temperatuur/te-koud/te-koud.component';
import { ToolingComponent } from './tooling/tooling.component';
import { NewsComponent } from './news/news/news.component';


export const APP_ROUTES: Routes = [
  { path: 'temperatuur/huidige-temperatuur', component: CurrentTempComponent, data: new RoutingData('Huidige temperatuur') , resolve: {
    dataVoorDezeRoute: CurrentTempAvailableResolver
    } },
  { path: 'temperatuur/te-koud', component: TeKoudComponent, data: new RoutingData('Te koud')  },
  { path: 'fietsen', component: FietsComponent, data: new RoutingData('Fietsen')  },
  { path: '', component: HomeComponent, data: new RoutingData('HOME')  },
  { path: 'faq', component: FaqIndexComponent,  loadChildren: () => import('./faq/faq-routes').then(mod => mod.FAQ_ROUTES), data: new RoutingData('Faq')  },
  { path: 'formulieren', loadChildren: () => import('./formulieren/formulieren-routes').then(mod => mod.FORMULIEREN_ROUTES), data: new RoutingData('Formulieren')  },
  { path: 'http', loadChildren: () => import('./http/http-routes').then(mod => mod.HTTP_ROUTES) },
   {path: 'routing', component:  RoutingIndexComponent, loadChildren: () => import('./routing/routing-routes').then(mod => mod.ROUTING_ROUTES)},
  { path: 'rxjs',component:  RxjsComponent},
  { path: 'tooling', component: ToolingComponent, loadChildren: () => import('./tooling/tooling-routes').then(mod => mod.TOOLING_ROUTES) },
  { path: 'store', loadChildren: () => import('./store/store-routes').then(mod => mod.STORE_ROUTES) },
  { path: 'redux', loadChildren: () => import('./redux/redux-routes').then(mod => mod.REDUX_ROUTES) },
  { path: 'paging', loadChildren: () => import('./paging/paging-routes').then(mod => mod.PAGING_ROUTES) },
  { path: 'news', component: NewsComponent, loadChildren: () => import('./news/news-routes').then(mod => mod.NEWS_ROUTES) },
  { path: '**', component: NotFoundComponent, data: new RoutingData('404 not found')  }


];

