import { InMemoryDbService } from 'angular-in-memory-web-api';

import { Product } from './product';

export class ProductData implements InMemoryDbService {

    createDb() {
        const products: Product[] = [
            {
                id: 1,
                productName: 'Tuinhark 12-tand 160cm',
                productCode: 'GDN-MAN-0012',
                description: 'De tuinhark 12-tand is ideaal voor het verwijderen van tuinafval uit de borders en het egaal harken van grind. De tuinhark is geproduceerd uit verzinkt staal wat roesten tegengaat en hierdoor een lange levensduur heeft. Tevens is de tuinhark voorzien van een 160cm FSC houten steel, wat zorgt voor een fijne werkhouding. De tuinhark heeft 12 tanden. De keuze voor het aantal tanden is afhankelijk van wat voor oppervlakte er geharkt moet worden.',                starRating: 3.2
            },
            {
              id: 2,
              productName: 'Torxschroevendraaier TX 20 1000V',
              productCode: 'GDN-MAN-0013',
              description: 'Ergonomisch verbeterde twee-componenten greep voor een moeiteloze en handvriendelijke krachtoverbrenging. Hoekige handgreep, rolt niet weg. Chroom-vanadium-molybdeen-staal',
              starRating: 3.4
          },
            {
                id: 4,
                productName: ' nylon en zwart rubber hamer Ø 40mm 450 gram',
                productCode: 'GDN-0023',
                description: 'Deze rubber- en kunststofhamer heeft een kop met een diameter van 40 mm. De hamer is voorzien van een duurzaam houten handvat.',
                starRating: 4.2
            },
            {
                id: 5,
                productName: 'Klauwhamer RVS rubberen grip',
                productCode: 'TBX-0048',
                description: 'Bi-mat antislip handgreep voor een vaste grip op de hamer tijdens het gebruik, Langere handgreep voor verbeterde prestaties, Glasvezel steel voor kracht & duurzaamheid, Epoxy verbinding tussen kop en steel, Overslag bescherming',
                starRating: 4.8
            },
            {
                id: 8,
                productName: 'handzaag 350mm 15/16 TPI (300-14-F15/16-HP)',
                productCode: 'TBX-0022',
                description: 'Prizecut handzaag 350 mm, Aantal tanden: 15/16 tanden per inch, Multifunctionele zaag met fijne vertanding, Voor gemakkelijk zagen in verschillende materialen zoals bijv. aluminium, kunststof, laminaat, hout en spaanplaat, Vastgeschroefde kunststof handgreep.',
                starRating: 3.7
            },
            {
                id: 10,
                productName: '10-delige metrische t-grepen inbusset ',
                productCode: 'GMG-0042',
                description: 'Chroom vanadium sleutels met gevormde T-handvaten, In metalen opbergstandaard, Maten: 2 / 2,5 / 3 / 4 / 4,5 / 5 / 5,5 / 6 / 8 en 10 mm',
                starRating: 4.6
            }
        ];
        return { products };
    }
}
