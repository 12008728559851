/* NgRx */
import { createReducer, on, createAction, createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductState } from './Product.State';
import * as ProductActions from './products.actions';


const initialState: ProductState = {
  showProductCode: false,
  currentProduct: null,
  products: [],
  error : ''
}


export const productReducer = createReducer<ProductState>(
  initialState,
  on(ProductActions.toggleProductCodeAction, (state) : ProductState => {
    console.log('[productReducer toggleProductCodeAction] original state: ', JSON.stringify(state));
    return {
      ...state,
      showProductCode: !state.showProductCode
    };
  }),
  on(ProductActions.setCurrentProductAction, (state, action) : ProductState => {
    console.log('[productReducer setCurrentProductAction] original state: ', JSON.stringify(state));
    console.log('[productReducer setCurrentProductAction] action: ', JSON.stringify(action));
    return {
      ...state,
      currentProduct: action.product
    };
  }),
  on(ProductActions.clearCurrentProductAction, (state) : ProductState => {
    //console.log('[productReducer clearCurrentProductAction] original state: ', JSON.stringify(state));
    return {
      ...state,
      currentProduct: null
    };
  }),

  on(ProductActions.initializeCurrentProductAction, (state) : ProductState => {
    console.log('[productReducer initializeCurrentProductAction] original state: ', JSON.stringify(state));
    return {
      ...state,
      currentProduct:{
        id :0,
        productName: 'fill...',
        productCode: 'New',
        description: '',
        starRating: 0
      }
    };
  }),
  on(ProductActions.loadProductsSuccessAction, (state, action) : ProductState => {
    return {
      ...state,
      products: action.products,
      error: ''
    };
  }),
  on(ProductActions.loadProductsFailedAction, (state, action) : ProductState => {
    return {
      ...state,
      products: [],
      error: action.errorMessage
    };
  }),
  on(ProductActions.updateProductSuccesAction, (state, action) : ProductState => {
    const updateProducts = state.products.map(
      item => action.product.id === item.id? action.product : item
    );
    return {
      ...state,
      products: updateProducts,
      currentProduct: null,
      error: ''
    };
  }),
  on(ProductActions.updateProductFailedAction, (state, action) : ProductState => {
    return {
      ...state,
      currentProduct: null,
      error: action.errorMessage
    };
  }),
  on(ProductActions.createProductSuccesAction, (state, action) : ProductState => {
    return {
      ...state,
      products: [ ...state.products, action.product ],
      currentProduct: null,
      error: ''
    };
  }),
  on(ProductActions.createProductFailedAction, (state, action) : ProductState => {
    return {
      ...state,
      currentProduct: null,
      error: action.errorMessage
    };
  }),
  on(ProductActions.deleteProductSuccesAction, (state, action) : ProductState => {
    return {
      ...state,
      products: state.products.filter(product => product.id !== action.productId),
      currentProduct: null,
      error: ''
    };
  }),
  on(ProductActions.deleteProductFailedAction, (state, action) : ProductState => {
    return {
      ...state,
      currentProduct: null,
      error: action.errorMessage
    };
  }),
  on(ProductActions.cancelEditAction, (state, action) : ProductState => {
    return {
      ...state,
      currentProduct: null,
    };
  }),
);

