<div class="container">
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs ">
        <li class="nav-item"><a class="nav-link" routerLink="angular" routerLinkActive="active actiefMenu">Angular</a>
        </li>
        <li class="nav-item"><a class="nav-link" routerLink="aspnet" routerLinkActive="active actiefMenu">ASP.NET</a>
        </li>
        <li class="nav-item"><a class="nav-link" routerLink="dotnet" routerLinkActive="active actiefMenu">DOT.NET</a>
        </li>
        <li class="nav-item"><a class="nav-link" routerLink="csharp" routerLinkActive="active actiefMenu">C#</a>
        </li>
        <li class="nav-item"><a class="nav-link" routerLink="typescript" routerLinkActive="active actiefMenu">TypeScript</a>
        </li>
      </ul>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
