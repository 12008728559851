import { Component } from '@angular/core';

@Component({
    selector: 'app-te-koud',
    templateUrl: './te-koud.component.html',
    styleUrls: ['./te-koud.component.sass'],
    standalone: true
})
export class TeKoudComponent  {
  code = `
  { path: 'temperatuur/huidige-temperatuur', component: CurrentTempComponent, data: new RoutingData('Huidige temperatuur') , resolve: {
    dataVoorDezeRoute: CurrentTempAvailableResolver
    } },`;
}
